<template>
  <div class="xuezhe_div">
    <img  src="https://www.sanguogame.com.cn/image/koei/san8/ott1.jpg" />
    <div class="right">
      <div class="header">
        <span class="name">陆九渊</span>
        <span class="desc">({{ info.desc }})</span>
      </div>
      <div class="comment">{{ info.comment }} </div>
    </div>
  </div>
</template>
<script>
// import store from '@/store'
// import router from '@/router/index.js'
// import Utils from '@/utils/utils'
// import axios from 'axios'

export default {
  name: 'OneXuezhe',
  props: {
    infox: Map
  },
  data () {
    return {
      info: this.infox
    }
  }
}
</script>

<style scoped>
.xuezhe_div{
  display: inline-block;
  width: 320px;
  height: 120px;
  padding: 16px 16px;
  background-color: #F8FAFB;
  margin: 0px 10px 10px 0px;
  vertical-align: top;
  overflow: hidden;
}
.xuezhe_div img{
  width: 90px;
  height: 120px;
  display: inline-block;
}
.xuezhe_div .right{
  vertical-align: top;
  width: 220px;
  display: inline-block;
  margin-left: 10px;
}
.xuezhe_div .right .header{
  overflow: hidden;
  white-space:nowrap;

}
.xuezhe_div .right .header .name{
  color: #000;
  font-weight: bold;
  margin-right: 6px;
  vertical-align: middle;
}
.xuezhe_div .right .header .desc{
  color: #aaa;
  vertical-align: middle;
  font-size: 14px;
  white-space:nowrap;
  overflow: hidden;
}
.xuezhe_div .right .comment{
  width: 210px;
  height: 84px;
  padding: 6px 6px 6px 6px;
  background-color: rgba(221, 221, 221, 0.2);
  font-size: 14px;
  color: #999;
  border-radius: 4px;
}
</style>
