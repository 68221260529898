<template>
  <div  @scroll="updateActiveTab">
    <HeaderView :page_index="-1" :need_fixed="true"></HeaderView>
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;margin: 30px;" />
    <div v-else class="home">
      <el-container >
        <el-container>
          <el-aside width="200px" id="sidebar" style="padding-top: 60px;">
            <div :class="['tab', activeIndex==0 ? 'active' : '']" @click="scrollToSection(0)">学者评价</div>
            <div :class="['tab', activeIndex==1 ? 'active' : '']" @click="scrollToSection(1)">UP主评价</div>
          </el-aside>
          <el-container style="margin: 0px 20px 20px 30px;">
            <el-skeleton v-if="loading3" :rows="5" animated  style="text-align: left;" />
            <el-main  v-else style="text-align: left;">
              <div id="content">
                <div id="sections">
                  <div id="xuezhe" class="section" >
                    <div></div>
                    <div  style="margin:18px 0px 10px 0px;"> <el-tag class="ml-2" size="large">学者评价</el-tag></div>
                    <div class="items">
                      <OneXuezhe :infox="info"  v-for="(info, index) in xuezhe_list" :key="index"  />
                    </div>
                  </div>
                  <div id="upper" class="section">
                    <div  style="margin:18px 0px 10px 0px;"> <el-tag class="ml-2" size="large">up主评价</el-tag></div>
                    <div class="items">
                      <OneUpper :infox="info"  v-for="(info, index) in upper_list" :key="index"  />
                    </div>
                  </div>
                </div>
                <div id="others" style="height: 360px;"></div>
              </div>
            </el-main>
          </el-container>
        </el-container>
      </el-container>
      <FooterView show_what="line2" page_name="Gratitude"/>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import HeaderView from '@/components/other/HeaderView'
import FooterView from '@/components/other/FooterView'
import OneXuezhe from '@/components/items/OneXuezhe'
import OneUpper from '@/components/items/OneUpper'

// import router from '@/router/index.js'
// import { ElLoading } from 'element-plus'

export default {
  name: 'Gratitude',
  mounted () {
    this.get_index()
    window.addEventListener('scroll', this.updateActiveTab) // 监听页面滚动
  },
  components: {
    FooterView, HeaderView, OneXuezhe, OneUpper
  },
  data () {
    return {
      loading: true,
      niming: store.state.niming,
      xuezhe_list: [],
      upper_list: [],
      activeIndex: -1
    }
  },
  methods: {

    get_index (e) {
      var _this = this
      axios.post(Utils.check_is_niming() ? '/gratitude_index_unlogin/' : '/gratitude_index/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.xuezhe_list = res.data.data.xuezhe_list
          this.upper_list = res.data.data.upper_list
          this.loading = false

          setTimeout(function () {
            const hash = window.location.hash
            console.log(222, hash)
            if (!hash) {
              // document.body.scrollTop = 0
              _this.scrollTop()
            } else {
              _this.scrollToAnchor(hash)
              // const element = document.querySelector(hash)
              // if (element) {
              //   element.scrollIntoView({ behavior: 'smooth' })
              // }
            }
            _this.updateActiveTab()
          }, 100)
          // const hash = window.location.hash
          // console.log(222, hash)
          // if (!hash) {
          //   this.scrollTop()
          // } else {
          //   this.scrollToAnchor(hash)
          // }
        })
    },
    scrollToAnchor (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollTop () {
      document.body.scrollTop = 0
    },
    scrollToSection (index) {
      var sections = document.getElementsByClassName('section')
      var section = sections[index]
      section.scrollIntoView({ behavior: 'smooth' })
      // this.activeIndex = index
    },
    updateActiveTab () {
      console.log(33333)

      var sections = document.getElementsByClassName('section')
      var tabs = document.getElementsByClassName('tab')

      var activeIndex = -1
      var ScreenMiddleY = window.innerHeight / 2

      var rect0 = document.getElementById('others').getBoundingClientRect()

      console.log(rect0.top)

      if (rect0.top < 0) {
        for (let i = 0; i < tabs.length; i++) {
          tabs[i].classList.remove('active')
        }
      } else {
        for (let i = 0; i < sections.length; i++) {
          var section = sections[i]
          var rect = section.getBoundingClientRect()
          var sectionTop = rect.top

          if (sectionTop < ScreenMiddleY && sectionTop >= 0) {
            activeIndex = i
            console.log(activeIndex, this.activeIndex)
            this.activeIndex = i
            break
          }
        }

        if (activeIndex === -1) {
          for (let i = 0; i < sections.length; i++) {
            const section = sections[i]
            const rect = section.getBoundingClientRect()
            const sectionTop = rect.top
            if (sectionTop < ScreenMiddleY) {
              activeIndex = i
              this.activeIndex = i
            }
            console.log('xx', activeIndex, this.activeIndex)
          }
        }
        // for (let i = 0; i < tabs.length; i++) {
        //   const tab = tabs[i]
        //   tab.classList.remove('active')
        // }
        // if (activeIndex !== -1) {
        //   tabs[activeIndex].classList.add('active')
        // }
      }
    }
  }
}
</script>
<style scoped>

#sidebar {
  position: fixed;
  width: 200px;
  /* background-color: #1ec990; */
}

.tab {
  margin: 16px 16px;
  padding: 6px 5px;
  background: lightblue;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
.active {
  color: #50b7c1;
  /* color: #ffd04b; */
}

.section {
  margin: 0px 30px 20px 170px;
  padding-top: 60px;
  /* background-color: #f2f2f2; */
  /* width: 900px; */
}

</style>
