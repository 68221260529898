<template>
  <div class="upper_div">
    <div class="header">
      <img src="https://www.sanguogame.com.cn/image/koei/san8/san8_k04.jpg"  />
      <div class="right">
        <div class="">
          <span class="name">烛之武</span>
          <span class="desc">{{ info.upper.desc }}</span>
        </div>
        <div class="title">作品《{{info.video.title }}》</div>
      </div>
    </div>
    <div class="video">
      <img src="https://p3-search.byteimg.com/img/pgc-image/1524106666182a7bfd2d939~tplv-tt-cs0:605:375.jpeg" />
    </div>
    <div class="comment">尽快好久好久可好看交换机卡户籍卡户籍卡尽快会尽快黄河科技尽快会尽快尽快尽快会尽快黄河科技尽快会尽快尽快黑科技会尽快</div>
  </div>
</template>
<script>
// import store from '@/store'
// import router from '@/router/index.js'
// import Utils from '@/utils/utils'
// import axios from 'axios'

export default {
  name: 'OneUpper',
  props: {
    infox: Map
  },
  data () {
    return {
      info: this.infox
    }
  }
}
</script>

<style scoped>
.upper_div{
  /* display: inline-block;
  width: 320px;
  height: 120px;
  padding: 16px 16px;
  background-color: #F8FAFB;
  margin: 0px 10px 10px 0px;
  vertical-align: top;
  overflow: hidden;
}
#upper li{ */
  display: inline-block;
  width: 320px;
  /* height: 250px; */
  padding: 16px 16px;
  background-color: #F8FAFB;
  margin: 0px 10px 10px 0px;
  vertical-align: top;
  overflow: hidden;
}
.upper_div .header{
  margin-bottom: 10px;
  white-space:nowrap;
}
.upper_div .header img{
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 6px;
}
.upper_div .header .right{
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  white-space:nowrap;
}
.upper_div .video img{
  width: 320px;
  height: 240px;
  display: block;
}

.upper_div .header .name{
  color: #000;
  font-weight: bold;
  margin-right: 6px;
  vertical-align: middle;
}
.upper_div .header .desc{
  color: #aaa;
  vertical-align: middle;
  font-size: 14px;
  white-space:nowrap;
}
.upper_div .header .title{
  color: #555;
  font-size: 14px;
}
.upper_div .comment{
  height: 60px;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  background-color: rgba(221, 221, 221, 0.2);
  font-size: 14px;
  color: #999;
  border-radius: 4px;
  overflow: hidden;
}
</style>
